/**
 * Usage: @include fontStyles(headline, xl); // adds the headline-xl style to rule
 *        @include fontStyles(body, 14, true); // adds body-2-bold stlye to rule
*/
@mixin fontStyles($font, $size: 18, $isBold: false, $weight: 400) {
  font-family: var(--font-family-sans);

  @if ($font == headline) {
    font-weight: 600;
    @if ($size == xl) {
      font-size: 32px;
      line-height: 44px;
      letter-spacing: 0.005em;
    }
    @if ($size == lg) {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: 0.005em;
    }
    @if ($size == md) {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.01em;
    }
    @if ($size == sm) {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.01em;
    }
  }

  @if ($font == body) {
    letter-spacing: 0.01em;
    @if ($isBold == true) {
      font-weight: bold;
    } @else {
      font-weight: normal;
    }
    @if ($size == 18) {
      font-size: 18px;
      line-height: 26px;
    }
    @if ($size == 16) {
      font-size: 16px;
      line-height: 24px;
    }
    @if ($size == 14) {
      font-size: 14px;
      line-height: 22px;
    }
    @if ($size == 12) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  @if ($font == card) {
    font-size: $size;
    font-weight: $weight;
    line-height: 20px;
    letter-spacing: 0.01em;
  }

  @if ($font == button) {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: 0.01em;
  }
}

/**
 * Usage: @include limitTextLines(2); // displays as manys as 2 lines of text with '..."  
 * https://stackoverflow.com/questions/3922739/limit-text-length-to-n-lines-using-css
*/
@mixin limitTextLines($numLines: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $numLines; /* number of lines to show */
  line-clamp: $numLines;
  -webkit-box-orient: vertical;
}
