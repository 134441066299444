.dateSelector-inline {
  color: #2196f3;
  display: inline-block !important;
}

.dateSelector-inline .DayPickerInput {
  display: inline-block;
}

.dateSelector-inline .DayPickerInput input {
  border: 0;
}

.dateSelector .DayPickerInput input {
  display: inline-block;
  width: 70px;
  padding: 0 5px;
}

.DayPicker {
  font-size: 12.5px;
}

.DayPicker-Months {
  height: 270px;
}

.DayPicker-Month {
  margin: 0 var(--font-size-0);
  margin-top: var(--font-size-0);
}

.DayPicker-Day {
  border-radius: 0 !important;
  color: #616161;
  padding: var(--font-size--2);
}

.DayPicker-Caption > div {
  color: #616161;
  font-weight: 500;
  font-size: var(--font-size-1);
}

.DayPicker-Day--selectedRange {
  background-color: #f0f8ff;
  color: #616161;
}

.DayPicker-Day--outside {
  background-color: #ffffff !important;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff;
}

.DayPicker-Day--start:not(.DayPicker-Day--outside) {
  background-color: #2196f3;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  color: #ffffff;
}

.DayPicker-Day--start:not(.DayPicker-Day--outside):hover {
  background-color: #2196f3 !important;
}

.DayPicker-Day--end {
  background-color: #2196f3;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  color: #ffffff;
}

.DayPicker-Day--end:hover {
  background-color: #2196f3 !important;
}

.DayPickerInput-Overlay {
  width: 200px;
}
